import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, Table, Button, Alert, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faPlus, faSearch, faEdit, faTrash, faBroom, faFilter } from "@fortawesome/free-solid-svg-icons";
import RCPagination from "src/components/common/RCPagination";
import ActionIcon from "src/components/general/ActionIcon";
import axios from "axios";
import { CONCEPT_EDIT, CONCEPT_NEW } from "src/utils/constants";
import { push } from "connected-react-router";
import { clearBankAccountListData } from "src/redux/bankAccountsFundRequest/bankAccountsFundRequestActions";
import { useForm } from "react-hook-form";
import { tryListConcepts, tryDeleteConcept ,tryListConceptsSelect} from "src/redux/conceptRequest/conceptRequestActionCreator";
import AppLoading from "src/components/common/AppLoading";
import { getConceptListData, getConceptListIsFetching } from "src/redux/conceptRequest/conceptRequestReducer";
import ConceptDetailModal from "./ConceptDetailModal";
import { clearListConcept, setConceptToEdit } from "src/redux/conceptRequest/conceptRequestActions";
import { getClearObject } from "src/utils/utils";
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getListAllServiceAdministrativeByUserData } from "src/redux/administrativeService/administrativeServiceReducer";
import { tryListServicesByUserAndExercise, tryListAllServiceAdministrativeByUser } from "src/redux/administrativeService/administrativeServiceActionCreator";
import { AdministrativeServiceData } from 'src/utils/administrativeServiceUtils.js';

const ConceptListPage = () => {

  const { handleSubmit, register, reset } = useForm();
  const dispatch = useDispatch();

  const [dataModal, setDataModal] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const conceptListData = useSelector((state) => getConceptListData(state));
  const conceptListIsFetching = useSelector((state) => getConceptListIsFetching(state));
  const [filterObject, setFilterObject] = useState({});
  const totalCount = conceptListData?.metadata?.filteredCount;
  const pageNumber = conceptListData?.metadata?.page;
  const serviceData = AdministrativeServiceData();

  // User Data
  const email = useSelector(state => getEmail(state));
  const listUserData = useSelector(state => getUserListData(state));
  const userFound = listUserData?.records?.find(item => item?.email == email);
  const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
  const serviceAdministrativeByUser = useSelector(state => getListAllServiceAdministrativeByUserData(state))
  const listIdServiceAdministrativeByUser = serviceAdministrativeByUser?.map(i => i.code);
  const [serviceId, setServiceId] = useState(listIdServiceAdministrativeByUser);

  //Cancel Request
  const CancelToken = axios.CancelToken;
  let cancelSource = CancelToken.source();

  const handleAdministrativeService = value => {
    const serviceValue = value != '' ? value : defaultParams?.administrativeServiceId
    setServiceId(serviceValue)
    const newFilterObject = {
        ...defaultParams,
        ...filterObject,
        administrativeServiceId: serviceValue,
    };
    fetchList(newFilterObject);
  };

  const onClickSeeDetails = (item) => {
    setDataModal(item);
    handleShow(true);
  };

  const onPageChange = (pNumber) => {
    const params = {
      ...defaultParams,
      ...filterObject,
      administrativeServiceId: serviceId,
      page: pNumber - 1,
    };
    fetchList(params);
  };

  const onClickEdit = (item) => {
    dispatch(setConceptToEdit(item));
    dispatch(push(CONCEPT_EDIT));
  };

  const onClickNew = () => {
    dispatch(push(CONCEPT_NEW));
    dispatch(tryListConceptsSelect());
  };

  const validateActionIcon = (item) => {
    let actionIconItem = {
        text: '',
        validation: true
    };
    if(!item){
      actionIconItem.text = 'Concepto o subconceptos asociado otros registros'
      actionIconItem.validation = false;
    }
    return actionIconItem
  };

  const defaultParams = {
    page: 0,
    size: 10,
    administrativeServiceId: listIdServiceAdministrativeByUser
  };

  const fetchList = (params) => {
    dispatch(tryListConcepts(params))
  };

  const onClickDelete = (concept) => {
    swal({
      title: "Confirmación",
      text: "¿Realmente desea eliminar este registro?",
      icon: "warning",
      buttons: ["Cancelar", "Aceptar"],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(tryDeleteConcept(concept?.id)).then((response) => {
          if (response?.status == 204) {
            dispatch(clearBankAccountListData());
            loadAllData();
          }
        });
      }
    });
  };

  const clearFilters = () => {
    setFilterObject({});
    reset();
    loadAllData();
    setServiceId(listIdServiceAdministrativeByUser);
  };

  let newFilterObject;
  const onSubmitForm = (data) => {
    if (!conceptListIsFetching) {
      cancelSource.cancel();
      cancelSource = CancelToken.source();
      newFilterObject = {
        ...defaultParams,
        administrativeServiceId: serviceId,
        conceptDescription: data?.conceptDescription.toUpperCase(),
        subConceptDescription: data?.subConceptDescription.toUpperCase(),
      };
      newFilterObject = getClearObject(newFilterObject);
      setFilterObject(newFilterObject);
      dispatch(tryListConcepts(newFilterObject));
    }
  };

  const loadAllData = () => {
    dispatch(clearListConcept());
    fetchList(defaultParams);
  };

  useEffect(() => {
    loadAllData();
    dispatch(tryListServicesByUserAndExercise(userFound?.id, globalSelectedPeriod?.id));
    dispatch(tryListAllServiceAdministrativeByUser(userFound?.id));
  }, []);

  return (
        <Container fluid>
          <Card className="my-3">
            <Card.Header className="h6">
              Conceptos de Liquidación de Haberes
            </Card.Header>
            <Card.Body>
              <div className='d-flex'>
                <Button className="mb-3 btn-md btn-success" onClick={onClickNew}>
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  Nuevo Concepto
                </Button>
                {serviceData.length > 1 &&
                  <select
                    value={serviceId}
                    className='form-control col-3 mx-3'
                    onChange={(e) => handleAdministrativeService(e.target.value)}
                  >
                    <option value={''}>Todos los servicios</option>
                    {Array.isArray(serviceData) &&
                      serviceData.map(item => (
                        <option className='py-2' value={item.id} key={item.id}>
                          {item.code} - {item.shortName}
                        </option>
                      ))
                    }
                  </select>
                }
              </div>
              <Form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
                <Table striped hover size="sm" responsive>
                  <thead>
                    <tr>
                      <th className="text-center align-middle" width="40%">Concepto</th>
                      <th className="text-center align-middle" width="40%">Sub-Concepto</th>
                      <th className="text-center align-middle" width="10%">Acciones</th>
                    </tr>
                    <tr className="secondary">
                      <th className="text-center align-middle">
                        <Form.Control
                          className="text-center"
                          size="sm"
                          type="text"
                          name="conceptDescription"
                          ref={register}
                        />
                      </th>
                      <th className="text-center align-middle">
                        <Form.Control
                          className="text-center"
                          type="text"
                          name="subConceptDescription"
                          ref={register}
                        />
                      </th>
                      <th className="text-center align-middle">
                        <div className="d-flex justify-content-around">
                          <ActionIcon
                            id="search-button"
                            className="btn-primary search-button text-white-color"
                            toolTipText="Filtrar"
                            icon={faFilter}
                            type="submit"
                            onSubmit={onSubmitForm}
                          />
                          <ActionIcon
                            id="clean-filter"
                            className="btn-primary clean-filter text-white-color"
                            toolTipText="Limpiar filtros"
                            icon={faBroom}
                            type="reset"
                            onClick={() => clearFilters()}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-black-color">
                    {conceptListData?.records?.length > 0 ? (
                      <>
                        {conceptListData.records.map((item) => (
                          <tr key={item?.id}>
                            <td className="text-center align-middle">
                              {item?.description}
                            </td>
                            <td
                              className="text-center align-middle truncate maxTruncate"
                              title={item.subConcept.map(subconcepto => subconcepto.description).join(" - ")}
                            >
                              {
                                item.subConcept.map(subconcepto => subconcepto.description).join(" - ")
                              }
                            </td>
                            <td className="text-center align-middle p-0 m-0 table-responsive btn-group">
                              <ActionIcon
                                size="lg"
                                id="credit-query"
                                toolTipText="Detalle del Registro"
                                icon={faSearch}
                                onClick={() => onClickSeeDetails(item)}
                              />
                              <ActionIcon
                                size="lg"
                                toolTipText={"Editar Registro"}
                                icon={faEdit}
                                onClick={() => onClickEdit(item)}
                              />
                              {                       
                              <ActionIcon
                                size="lg"
                                className={!item?.canDelete ? "not-allowed" : ''}
                                toolTipText="Eliminar Registro"
                                icon={faTrash}
                                onClick={() => validateActionIcon( item?.canDelete ).validation && onClickDelete(item) }                               
                              />
                              }
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center" >
                          {!conceptListIsFetching && (
                            <Alert variant="info" className="mb-0">
                              <FontAwesomeIcon icon={faExclamationTriangle} className="text-black-color mr-3" />
                              No hay registros
                            </Alert>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Form>
              <RCPagination
                activePage={pageNumber + 1}
                itemsCountPerPage={defaultParams.size}
                totalItemsCount={totalCount}
                totalItems={totalCount}
                thePage={pageNumber + 1}
                onChange={onPageChange}
                innerClass="justify-content-center"
              />  
            </Card.Body>
          </Card>
          <ConceptDetailModal
            show={show}
            handleClose={handleClose}
            dataModal={dataModal}
          />
          <AppLoading isLoading={conceptListIsFetching} />
        </Container>
  );
};

export default ConceptListPage;